import React, {useEffect} from 'react';
import useQuery from '../../../cmp/hooks/useQuery';
import {getAccessToken, post} from '../../../../api';
import Spinner from '../../../cmp/Spinner';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';

function PaypalOnCallback(){
    const {token, payerId, plan_id,cancel, subscription_id} = useQuery()
    const settings = useSelector((state)=>state.settings)
    const navigate = useNavigate();
    useEffect(()=>{
        if(plan_id && (token|| subscription_id)){
            // timeout is implemented to prevent API calls on useEffect getting called multiple times;
            const x = setTimeout(function(){
                post('/paymentplans/activate', {
                    payment_gateway:'paypal',
                    plan_id,
                    data:{
                        token,
                        subscription_id,
                        payerId,
                    }
                }).then((data) => {
                    if (data.success) {
                        window.location = data.after_payment_redirect || "/?payment_success=true";
                    } else {
                        navigate("/choose_plan")
                    }
                }).catch((e) => {
                    navigate("/choose_plan")
                })
            }, 300)
            return ()=>clearTimeout(x)
        }else if(cancel){
            navigate("/choose_plan");
        }
    },[cancel, token, payerId, plan_id, settings, subscription_id])
    if(!getAccessToken()){
        return <p className={"text-danger"}>Error: You are currently not logged in.</p>
    }
    return <div className="d-flex min-vh-100 align-items-center justify-content-center">
        <Spinner size={"lg"}/>
    </div>;
}

export default PaypalOnCallback
