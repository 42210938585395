import React, {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import Button from '../../cmp/form/Button';
import {get, getAccessToken, post} from '../../../api';
import {connect} from 'react-redux';
import Spinner from '../../cmp/Spinner';
import {Link} from 'react-router-dom';
import PlanCard from './components/PlanCard';
import {ShopifyOrderListener} from './components/ShopifyTestForPurchase';
import LogoRender from '../../cmp/LogoRender';
import FormattedHelmet from '../../cmp/intl/FormattedHelmet';

function SubscribePayment() {
    const [membership_plans, setMembershipPlans] = useState(null)
    const [loading, setLoading] = useState(false);
    const [planId, setPlanId] = useState(null)
    const [error, setError] = useState(null)
    useEffect(()=>{
        setLoading(true)
        get('/paymentplans/').then(({plans}) => {
            setLoading(false);
            setMembershipPlans(plans.sort((a, b) => a.cost - b.cost));
        })
    },[])

    const currentPlanId = () => {
        const morethanone = membership_plans.length > 1;
        return planId || (morethanone ? undefined : membership_plans[0].plan_id)
    }

    const onActivate = (plan_id) => {
        setLoading(true)
        setPlanId(plan_id)
        setError(false)
        post('/paymentplans/payment_url', {
            plan_id,
        }).then((data) => {
            setLoading(false);
            const {payment_gateway, error} = data;
            if(error){
                setError(error);
                return;
            }
            if(payment_gateway === "stripe"){
                if(window.top !== window.self){
                    window.top.location = data.session.url;
                }else {
                    document.location = data.session.url;
                }
            }
            else if(payment_gateway === "paypal"){
                const {approve} = data;
                if(window.top !== window.self){
                    window.top.location = approve
                }else {
                    document.location = approve
                }
            }
        })
    }


        if (!membership_plans) {
            if (loading) {
                return <BigCentralSpinner/>
            }
            return null;
        }
        if(Array.isArray(membership_plans) && membership_plans.length === 0){
            return <p className={"text-center"}>
                No membership plans configured for this user
            </p>
        }
        if(!getAccessToken()){
            return <p className={"text-danger"}>Error: You are currently not logged in.</p>
        }
        const num_plans = membership_plans.length;
        return <VerticalCenterContainer>
            <ShopifyOrderListener />
            <FormattedHelmet id={"Activate membership"}/>
            <div className={"container my-4 py-4"}>
                <div className="text-center">
                    <LogoRender />
                </div>
                <h2 className={'text_on_bg text-center mb-4'}>
                    <FormattedMessage id={"Activate membership"}/>
                </h2>
                {
                    error && <p className={"text-danger text-center"}>{error}</p>
                }
                <div className={"row row-cols-1 g-2 row-cols-md-2 row-cols-lg-3 justify-content-center"}>
                    {
                        membership_plans.map((item) => {
                            const cls = Math.max(4, Math.min(12 / num_plans, 6))
                            return <div className={"col d-flex flex-row"} key={String(item.plan_id)}>
                                    <PlanCard loading={loading}
                                              isChosen={item.plan_id === planId}
                                              {...item}
                                              num_plans={num_plans}
                                              onChoose={()=>onActivate(item.plan_id)}/>
                                </div>
                            })
                        }
                    </div>
                    {
                        currentPlanId() && num_plans === 1 && membership_plans.some((a)=>a.plan_type !== "one_time_purchase") &&
                        <div className={"text-center mt-2"}>
                            {
                                error && <p className={"text-danger"}>{error}</p>
                            }
                            <Button className={"btn btn-primary"}
                                    loading={loading}
                                    onClick={()=>onActivate(currentPlanId())}>
                                <FormattedMessage id={"Continue"}/>
                            </Button>
                        </div>
                    }
                    <div className="mt-4 text-center activate_membership_logout">
                        <small>
                            <Link to={"/logout"}>
                                <FormattedMessage id={"Logout"}/>
                            </Link>
                        </small>
                    </div>
                </div>

        </VerticalCenterContainer>
}


function BigCentralSpinner() {
    return <VerticalCenterContainer>
        <Spinner size={"lg"}/>
    </VerticalCenterContainer>
}

function VerticalCenterContainer({children}) {
    return <div className="bg-light min-vh-100 d-flex align-items-center justify-content-center">
            {children}
    </div>
}


function mapStateToProps(state) {
    return {
        name: state.settings.name,
        email: state.settings.email
    }
}

export default connect(mapStateToProps)(SubscribePayment);
